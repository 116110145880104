import {useDispatch, useSelector} from "react-redux"
import "./JoinRoomProfile.css"
import { useEffect, useState } from "react"
import {Navigate, useNavigate, useParams} from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { joinRoom } from "../../../../actions/room.js"
import Gender from "../../../../Components/Gender/Gender.jsx"
import SimpleInput from "../../../../Components/Input/SimpleInput/SimpleInput.jsx"
import Button from "../../../../Components/Buttons/Button/Button.jsx";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../Firebase/config.js";
import Loader from "../../../../Components/Loader/Loader/Loader.jsx";
import Header from "../../../../Components/Header/Header.jsx";
import encrypt from "../../../../utils/Players/encrypt.js";

const JoinRoomProfile = ()=>{
    const dispatch = useDispatch()
    const room = useSelector(state=>state.room)
    const [name,setName] = useState("")
    const [gender,setGender] = useState("");
    const params = useParams();
    let navigate = useNavigate();
    const roomId = params.roomId
    const [loading,setLoading] = useState(true);
    const [ipAddress, setIPAddress] = useState('')
    useEffect(()=>{
        fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setIPAddress(data.ip))
    })
    if(room && room.status)
    {
        return <Navigate to="/lobby" />
    }

    try
    {
        const docRef = doc(db,"roomsV1",roomId.trim())
        getDoc(docRef).then((value)=>{
            if(!value.exists())
            {
                return navigate("/room/join?Error=Incorrect Room ID");
            }
            else
            {
                setLoading(false)
            }
        }).catch((e)=>{
            return <Navigate to="/room/join?Error=Internal Server Error, Please Try After Some Time" />
        });
    }
    catch(e)
    {
        return <Navigate to="/room/join?Error=Incorrect Room ID" />
    }

    const inputHandler = (e)=>{
        setName(e.target.value)
    }

    const genderHandler = (e)=>{
        setGender(e.target.checked?e.target.id:gender)
    }


    return <div className="welcome d-flex flex-column flex-wrap justify-content-center align-items-center py-1">
            <Header logoOnly={true} className="mt-5 mb-4" />
            {
                loading ? 
                <Loader />:
                <div className="col-md-6 col-sm-8 col-11 mt-2 d-flex justify-content-center bg-dark shadow-sm p-4 border border-4 rounded border-light flex-column gap-4 align-items-center">
                    <SimpleInput label={"What's Your Name?"} value={name} onChange={inputHandler} />
                    <Gender value={gender} onChange={genderHandler} />
                    <Button title="Join Room" loading={room.loading} onClickEvent={()=>{dispatch(joinRoom(roomId,name,gender,encrypt(ipAddress?ipAddress:"private")))}}/>
                </div>
            }
            <ToastContainer />
        </div>
    
}

export default JoinRoomProfile
