import { ANSWER_PICK_FAIL, ANSWER_PICK_REQUEST, ANSWER_PICK_SUCCESS, ANSWER_SUBMIT_FAIL, ANSWER_SUBMIT_REQUEST, ANSWER_SUBMIT_SUCCESS, GAME_BEGIN_FAIL, GAME_BEGIN_REQUEST, GAME_BEGIN_SUCCESS, NEXT_STAGE_FAIL, NEXT_STAGE_REQUEST, NEXT_STAGE_SUCCESS, PLAYER_READY_FAIL, PLAYER_READY_REQUEST, PLAYER_READY_SUCCESS, ROOM_CREATE_FAIL, ROOM_CREATE_REQUEST, ROOM_CREATE_SUCCESS, ROOM_JOIN_FAIL, ROOM_JOIN_REQUEST, ROOM_JOIN_SUCCESS } from "../constants/constants"

const initialState = {
    loading:false,
    status:false,
    answered:false,
    picked:false,
    ready:false
}
const roomReducer = (state=initialState,action)=>{
    switch(action.type)
    {
        case ROOM_CREATE_REQUEST:
        case ROOM_JOIN_REQUEST:
        case GAME_BEGIN_REQUEST:
        case ANSWER_SUBMIT_REQUEST:
        case ANSWER_PICK_REQUEST:
        case PLAYER_READY_REQUEST:
        case NEXT_STAGE_REQUEST:
        {
            return {
                ...state,
                loading:true
            }
        }
        case ROOM_CREATE_SUCCESS:
        case ROOM_JOIN_SUCCESS:
        {
            return {
                ...state,
                loading:false,
                status:true,
                playerId:action.payload.playerId,
                roomId:action.payload.roomId,
                avatar:action.payload.avatar,
                name:action.payload.name
            }
        }
        case ROOM_CREATE_FAIL:
        case ROOM_JOIN_FAIL:
        {
            return {
                ...initialState
            }
        }
        case GAME_BEGIN_SUCCESS:
        {
            return {
                ...state,
                loading:false
            }
        }
        case ANSWER_SUBMIT_SUCCESS:
        {
            return {
                ...state,
                answered:true,
                loading:false
            }
        }
        case ANSWER_PICK_SUCCESS:
        {
            return {
                ...state,
                picked:true,
                loading:false
            }
        }
        case PLAYER_READY_SUCCESS:
        {
            return {
                ...state,
                ready:true,
                loading:false
            }
        }
        case NEXT_STAGE_SUCCESS:
        {
            return {
                ...state,
                answered:false,
                picked:false,
                ready:false,
                loading:false
            }
        }
        case GAME_BEGIN_FAIL:
        case ANSWER_SUBMIT_FAIL:
        case ANSWER_PICK_FAIL:
        case PLAYER_READY_FAIL:
        case NEXT_STAGE_FAIL:
        {
            return {
                ...state,
                loading:false
            }
        }
        default:
        {
            return {
                ...state,
            }
        }
    }
}

export default roomReducer