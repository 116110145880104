import "./Loader.css"

const Loader = ({waiting=false})=>{
    return <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="loader triangle">
            <svg viewBox="0 0 86 80">
                <polygon points="43 8 79 72 7 72"></polygon>
            </svg>
        </div>
        <h2 className="text-light bg-dark text-center px-2">{waiting?"Waiting To Finish Level":"Loading"}</h2>
    </div>
}

export default Loader;