import getName from "../../utils/Players/getName"
import Avatar from "../Avatar/Avatar"
import { useSelector } from 'react-redux';

const WhoPicked = ({playersPicked})=>{
    const room = useSelector(state=>state.room)
    // console.log(playersPicked,Object.keys(playersPicked).length)
    return <>
            <h1 className="p-0 m-0 text-light text-center">Who Picked Your Answer?</h1>
            {
                playersPicked && Object.keys(playersPicked).length>0?
                <div className="d-flex gap-4 col-8 justify-content-center flex-wrap">
                    {
                        Object.keys(playersPicked).map((value,key)=><Avatar id={value} key={key} name={getName(value,room.playerId,playersPicked[value].name)} src={playersPicked[value].avatar} />)
                    }
                </div>:
                <h4 className="text-center text-secondary">Opps! No One Picked Your Answer</h4>
            }
    </>
}

export default WhoPicked;