import { Link } from "react-router-dom";
import "./Footer.css"

const Footer = ()=>{
    return <footer className="footer">
        <div>
            <div className="waves">
            <div className="wave" id="wave1"></div>
            <div className="wave" id="wave2"></div>
            <div className="wave" id="wave3"></div>
            <div className="wave" id="wave4"></div>
            </div>
            <p>
                &copy;2023 <div className="btn text-light p-0 pb-1 btn-group dropup">
                <span className="p-0 m-0 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Kudos People✨</span>
                <ul className="dropdown-menu">
                    <li className="d-flex justify-content-center align-items-center px-2">
                        <p className="m-0 d-flex">
                            <Link className="dropdown-item" to="#">Janvi Thakkar &#129392;</Link>
                            <Link className="dropdown-item" to="https://in.linkedin.com/in/janvi-thakkar-496514194"><i className="bi bi-linkedin text-primary"></i></Link>
                            <Link className="dropdown-item" to="https://github.com/Janvi-Thakkar"><i className="bi bi-github text-dark"></i></Link>
                            <Link className="dropdown-item" to="https://in.linkedin.com/in/janvi-thakkar-496514194"><i className="bi bi-twitter-x text-dark"></i></Link>
                        </p>
                    </li>
                    <li className="d-flex justify-content-center align-items-center px-2">
                        <p className="m-0 d-flex">
                            <Link className="dropdown-item" to="#">Muskan Rawat &#128525;</Link>
                            <Link className="dropdown-item" to="https://www.linkedin.com/in/muskan0701/"><i className="bi bi-linkedin text-primary"></i></Link>
                            <Link className="dropdown-item" to="https://github.com/Muskan07"><i className="bi bi-github text-dark"></i></Link>
                            <Link className="dropdown-item" to="https://twitter.com/muskan0701"><i className="bi bi-twitter-x text-dark"></i></Link>
                        </p>
                    </li>
                    <li className="d-flex justify-content-center align-items-center px-2">
                        <p className="m-0 d-flex">
                            <Link className="dropdown-item" to="#">Harshil Khamar &#128513;</Link>
                            <Link className="dropdown-item" to="https://www.linkedin.com/in/harshilkhamar/"><i className="bi bi-linkedin text-primary"></i></Link>
                            <Link className="dropdown-item" to="https://github.com/Harshil0512"><i className="bi bi-github text-dark"></i></Link>
                            <Link className="dropdown-item" to="https://twitter.com/HarshilKhamar05"><i className="bi bi-twitter-x text-dark"></i></Link>
                        </p>
                    </li>
                </ul>
                </div> | All Rights Reserved
            </p>
        </div>
    </footer>
}

export default Footer;