const Header = ({logoOnly=false,className})=>{
    return <div className={`col-md-8 col-12 mx-auto text-center ${className}`}>
        {
            !logoOnly && <img src="/Assets/Images/welcome.png" className="img-fluid" alt="Welcometopsych" />
        }
        <img src="/Assets/Images/friendstrianglelogo.png" className="img-fluid col-8 col-md-4" alt="friendstrianglelogo" srcSet="" />
    </div>
}



export default Header