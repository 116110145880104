import "./Stages.css"

const Stages = ({value,onChange})=>{
	const choiceSound = new Audio("/Assets/Sounds/choice.mp3");
    return <div className="InputGroup stages"> 
		<input type="radio" name="stage" id="5" value={value} onChange={(e)=>{choiceSound.play().then(()=>{}).catch(()=>{});onChange(e)}}/>
		<label htmlFor="5">5</label>
		<input type="radio" name="stage" id="7" value={value} onChange={(e)=>{choiceSound.play().then(()=>{}).catch(()=>{});onChange(e)}}/>
		<label htmlFor="7">7</label>
		<input type="radio" name="stage" id="10" value={value} onChange={(e)=>{choiceSound.play().then(()=>{}).catch(()=>{});onChange(e)}}/>
		<label htmlFor="10">10</label>
		<input type="radio" name="stage" id="15" value={value} onChange={(e)=>{choiceSound.play().then(()=>{}).catch(()=>{});onChange(e)}}/>
		<label htmlFor="15">15</label>
	</div>
}

export default Stages