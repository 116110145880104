import * as CryptoJS from 'crypto-js'
const encrypt = ( plainText) => {
    // const decrypt=CryptoJS.AES.decrypt;
    // console.log(plainText)
    try
    {
        const cipherText = CryptoJS.AES.encrypt(plainText, process.env.REACT_APP_SECRET).toString()
        // console.log(decrypt(cipherText,process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8))
        return cipherText
    }
    catch(e)
    {
        console.log(e)
    }
}

export default encrypt