import {Navigate, Route, Routes} from "react-router-dom" 
import Home from "./Pages/Home/Home";
import CreateRoom from "./Pages/Room/Create/Create";
import Lobby from "./Pages/Lobby/Lobby";
import ProtectedRoute from "./utils/ProtectedRoute/ProtectedRoute";
import JoinRoom from "./Pages/Room/Join/Room/JoinRoom";
import JoinRoomProfile from "./Pages/Room/Join/Profile/JoinRoomProfile";
import Game from "./Pages/Game/Game";
import Result from "./Pages/Result/Result";

const App = ()=>{
  return <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/room/" >
        <Route path="create" element={<CreateRoom />}></Route>
        <Route path="join" element={<JoinRoom />}></Route>
        <Route path="join/:roomId" element={<JoinRoomProfile />}></Route>
      </Route>
      <Route path="/game/" >
        <Route element={<ProtectedRoute />} >
          <Route path="" element={<Game />}></Route>
        </Route>  
      </Route>
      <Route element={<ProtectedRoute />} >
        <Route path="/result/:roomId" element={<Result />}></Route>
      </Route>
      <Route element={<ProtectedRoute />} >
        <Route path="/lobby" element={<Lobby />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
}

export default App;