import { doc, getDoc } from "firebase/firestore"
import LeaderBoard from "../../Components/LeaderBoard/LeaderBoard"
import { db } from "../../Firebase/config"
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";

const Result = ()=>{
    const room = useSelector(state=>state.room)
    const [players,setPlayers] = useState([])
    const [currentTrivia,setCurrentTrivia] = useState(undefined)
    const docRef = doc(db,'roomsV1',room.roomId)
    useEffect(()=>{
        getDoc(docRef)
        .then((data)=>{
            setPlayers(data.data().players)
            setCurrentTrivia(data.data().currentTrivia)
        })
        .catch((e)=>{
            console.log(e)
            return <Navigate to="/" />
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return <div className="col-md-6 col-sm-8 col-11 my-5 mx-auto d-flex flex-column justify-content-center bg-dark shadow-sm p-4 border border-4 rounded border-light flex-column gap-0 align-items-center">
        <Header logoOnly={true} className="m-4" />
        {currentTrivia && <LeaderBoard players={players} currentTrivia={currentTrivia} showReady={false} results={true} />}
    </div>
}

export default Result