import "./TextArea.css"

const TextArea = ({name,value,onChange,reference})=>{

    const removeError = (e)=>{ 
        e.target.classList.remove('error'); 
    };

    return <div className="customTextArea">
        <textarea name={name} id={name} cols="100" rows="20" value={value} ref={reference} onChange={(e)=>{
            onChange(e);
            removeError(e);
        
        }}></textarea>
    </div>
}

export default TextArea