const Avatar = ({id,name,src,tick})=>{
    return <div className="d-flex justify-content-center col-5 col-md-4 align-items-center flex-column">
            <img src={src} alt={name} className="img-fluid rounded-circle" />
            <h5 className="text-light d-flex justify-content-center align-items-center gap-2 mt-2 bg-dark p-1">
                {
                    tick && <img src="Assets/Images/tick.png" className="img-fluid col-4 col-md-2" alt="Answered"/>
                }
                {name}
            </h5>
        </div>
}

export default Avatar