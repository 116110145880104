import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../Firebase/config";
import "./Game.css"
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from "react";
import { Navigate } from 'react-router-dom';
import TextArea from "../../Components/Input/TextArea/TextArea";
import Button from "../../Components/Buttons/Button/Button";
import { answerPick, answerSubmit, nextStage } from "../../actions/game";
import { ToastContainer } from 'react-toastify';
import Avatar from "../../Components/Avatar/Avatar";
import getName from "../../utils/Players/getName";
import AnswerList from "../../Components/AnswerList/AnswerList";
import Alert from "../../Components/Alert/Alert";
import Loader from "../../Components/Loader/Loader/Loader";
import GlassCard from "../../Components/Card/GlassCard/GlassCard";
import LeaderBoard from "../../Components/LeaderBoard/LeaderBoard";
import ClipboardButton from "../../Components/Buttons/ClipboardButton/ClipboardButton";
import Header from "../../Components/Header/Header";
import WhoPicked from "../../Components/WhoPicked/WhoPicked";

const Game = ()=>{
    const room = useSelector(state=>state.room)
    const dispatch = useDispatch();
    const docRef = doc(db,'roomsV1',room.roomId)
    const [players,setPlayers] = useState(undefined)
    const [gameStatus,setGameStatus] = useState(true) 
    const [currentTrivia,setCurrentTrivia] = useState(undefined);
    const [allAnswered,setAllAnswered] = useState(false);
    const [allPicked,setAllPicked] = useState(false);
    const [answer,setAnswer] = useState("")
    const answerRef = useRef(undefined);
    const [show,setShow]=useState(true);
    const [gameEnded,setGameEnded] = useState(false);
    const [currentStage,setCurrentStage] = useState(0);
    const [totalStages,setTotalStages] = useState(0);
    useEffect(()=>{
        room && room.status &&
        onSnapshot(docRef,(docs)=>{
            setPlayers(docs.data().players)
            setGameStatus(docs.data().gameStatus)
            setCurrentTrivia(docs.data().currentTrivia)
            setAllAnswered(docs.data().currentTrivia.allAnswered)
            setAllPicked(docs.data().currentTrivia.allPicked)
            if(docs.data().currentTrivia.allReady)
            {
                // console.log("Here")
                dispatch(nextStage(room.roomId,docs.data().players[room.playerId].admin))
                setAnswer("")
            }
            if(docs.data().currentTrivia)
            setCurrentStage(docs.data().level);
            setTotalStages(docs.data().stages);
            setGameEnded(docs.data().ended)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const submitHandler = async ()=>{
        // console.log(answerRef.current)
        if(!answerRef.current.value)
        {
            answerRef.current.classList.add('error');
        }
        else
        {
            dispatch(answerSubmit(room.roomId,room.playerId,answer))
        }
    }

    const inputHandler = (e)=>{
        setAnswer(e.target.value)
    }

    const AnswerPickHandler = async (e)=>{
        if(room.playerId!==e.target.id)
        {
            dispatch(answerPick(room.roomId,room.playerId,e.target.id))
        }
        else
        {
            Alert("You Cannot Pick Your Own Answer!!")
            e.target.classList.add("error");
            setTimeout(()=>{
                e.target.classList.remove("error");
            },1000)
        }
    }

    const getPickedPlayers = ()=>{
        const filteredPlayers = Object.keys(Object.fromEntries(Object.entries(currentTrivia.playerStatus).filter(value=>value[1].pickId===room.playerId)))
        const pickedPlayers = Object.fromEntries(Object.entries(players).filter(value=>filteredPlayers.includes(value[0])))
        // console.log(filteredPlayers);
        // console.log(pickedPlayers);
        return pickedPlayers;
        
    }

    // console.log(currentTrivia.playerStatus[room.playerId].pickId)
    if(players && !gameStatus)
    {
        return <Navigate to="/" />
    }
    if(gameEnded)
    {
        return <Navigate to={`/result/${room.roomId}`} />
    }
    else if(players && currentTrivia.playerStatus[room.playerId])
    {
        
        return <>
            <Header logoOnly={true} className="mt-5 mb-4" />
            <div className="d-flex justify-content-center mt-5 align-items-center col-12">
            {
                room && <ClipboardButton roomId={room.roomId} />
            }
            </div>
            {
                !currentTrivia.playerStatus[room.playerId].ready &&
                <div className="col-md-6 col-sm-8 col-11 my-5 mx-auto d-flex justify-content-center bg-dark shadow-sm p-4 border border-4 rounded border-light flex-column gap-4 align-items-center">
                {
                    allAnswered && allPicked && !show ?
                    <WhoPicked playersPicked={getPickedPlayers()} />:
                    <h4 className="text-light">{currentTrivia.question}</h4>
                }
                </div>
            }
            <div className="col-md-6 col-sm-8 col-11 my-5 mx-auto d-flex justify-content-center bg-dark shadow-sm p-4 border border-4 rounded border-light flex-column gap-0 align-items-center">
                
                {
                    room.loading?
                    <Loader />:
                    room && !currentTrivia.playerStatus[room.playerId].status ?<>
                        <h4 className="text-light">Your Thoughts</h4>
                        <TextArea name={"question"} value={answer} reference={answerRef} onChange={inputHandler} />
                        <Button title={"Submit"} loading={room.loading} onClickEvent={submitHandler}></Button>
                    </>:
                    !allAnswered?
                    <>
                        <h4 className="text-light">Waiting For Others</h4>
                        <div className="d-flex gap-4 col-8 justify-content-center flex-wrap">
                            {
                                currentTrivia && Object.keys(currentTrivia.playerStatus).map((value,key)=>{
                                    return <Avatar id={value} key={key} name={getName(value,room.playerId,players[value].name)} src={players[value].avatar} tick={currentTrivia.playerStatus[value].status}  />
                                })
                            }
                        </div>
                    </>:
                    room && !currentTrivia.playerStatus[room.playerId].picked?
                    <>
                        <h4 className="text-light">Pick One</h4>
                        <AnswerList answers={currentTrivia.playerStatus} onClick={AnswerPickHandler} />
                    </>:
                    !allPicked?<>
                    <h4 className="text-light">Waiting For Others</h4>
                    <div className="d-flex gap-4 col-8 justify-content-center flex-wrap">
                        {
                            currentTrivia && Object.keys(currentTrivia.playerStatus).map((value,key)=>{
                                return <Avatar id={value} key={key} name={getName(value,room.playerId,players[value].name)} src={players[value].avatar} tick={currentTrivia.playerStatus[value].picked}  />
                            })
                        }
                    </div>
                </>:
                room && !currentTrivia.playerStatus[room.playerId].ready?
                show?
                <GlassCard name={players[currentTrivia.playerStatus[room.playerId].pickId].name} show={setShow} />:
                <LeaderBoard currentStage={currentStage} totalStages={totalStages} players={players} currentTrivia={currentTrivia} />
                :<>
                    <h4 className="text-light">Waiting For Others</h4>
                    <div className="d-flex gap-4 col-8 justify-content-center flex-wrap">
                        {
                            currentTrivia && Object.keys(currentTrivia.playerStatus).map((value,key)=>{
                                return <Avatar id={value} key={key} name={getName(value,room.playerId,players[value].name)} src={players[value].avatar} tick={currentTrivia.playerStatus[value].ready}  />
                            })
                        }
                    </div>
                </>
                }
                <ToastContainer />
            </div>
        </>
    }
    else if(players && !currentTrivia.playerStatus[room.playerId])
    {
        return <>
            <Header logoOnly={true} className="mt-5 mb-4" />
            <div className="d-flex justify-content-center mt-5 align-items-center col-12">
            {
                room && <ClipboardButton roomId={room.roomId} />
            }
            </div>
            <div className="col-md-6 col-sm-8 col-11 my-5 mx-auto d-flex justify-content-center bg-dark shadow-sm p-4 border border-4 rounded border-light flex-column gap-4 align-items-center">
                <h4 className="text-light">{currentTrivia.question}</h4>
            </div>
            <div className="col-md-6 col-sm-8 col-11 my-5 mx-auto d-flex justify-content-center bg-dark shadow-sm p-4 border border-4 rounded border-light flex-column gap-0 align-items-center">
                <Loader waiting={true} />
            <ToastContainer />
            </div>
        </>
    }
    else
    {
        return <></>
    }
}

export default Game