import "./Mode.css"

const Mode = ({value,onChange})=>{
	const choiceSound = new Audio("/Assets/Sounds/choice.mp3");
    return <div className="InputGroupMode px-2 mode d-flex flex-column justify-content-around align-items-center"> 
		<div className="d-flex">
			<input type="radio" name="mode" id="truth_comes_out" value={value} onChange={(e)=>{choiceSound.play().then(()=>{}).catch(()=>{});onChange(e)}}/>
			<label htmlFor="truth_comes_out" className="d-flex flex-column justify-content-around" ><img src="/Assets/Images/truth_comes_out.png" className="img-fluid py-3 col-12" alt="Truth Comes Out" /><h5>Truth Comes Out</h5></label>
			<input type="radio" name="mode" id="corporate_gossips" value={value} onChange={(e)=>{choiceSound.play().then(()=>{}).catch(()=>{});onChange(e)}}/>
			<label htmlFor="corporate_gossips" className="d-flex flex-column justify-content-around" ><img src="/Assets/Images/corporate_gossip.png" className="img-fluid py-3 col-12" alt="Corporate Gossips" /><h5>Corporate Gossips</h5></label>
			<input type="radio" name="mode" id="trip_time" value={value} onChange={(e)=>{choiceSound.play().then(()=>{}).catch(()=>{});onChange(e)}}/>
			<label htmlFor="trip_time" className="d-flex flex-column justify-content-around" ><img src="/Assets/Images/trip_time.png" className="img-fluid py-3 col-12" alt="Corporate Gossips" /><h5>Trip Time</h5></label>
		</div>
	</div>
}

export default Mode