import { ANSWER_PICK_REQUEST, ANSWER_PICK_SUCCESS, ANSWER_SUBMIT_FAIL, ANSWER_SUBMIT_REQUEST, ANSWER_SUBMIT_SUCCESS, GAME_BEGIN_FAIL, GAME_BEGIN_REQUEST, GAME_BEGIN_SUCCESS, NEXT_STAGE_FAIL, NEXT_STAGE_REQUEST, NEXT_STAGE_SUCCESS, PLAYER_READY_FAIL, PLAYER_READY_REQUEST, PLAYER_READY_SUCCESS } from "../constants/constants"
import axios from "axios"
import { endpoint } from "../endpoint";
import Alert from "../Components/Alert/Alert";

export const begin = (roomId)=>{
    return async function(dispatch)
    {
        dispatch({type:GAME_BEGIN_REQUEST})
        try
        {
            const data = await axios.post(endpoint+"/api/game/begin",{roomId})
            if(data.data.status)
            {
                dispatch({type:GAME_BEGIN_SUCCESS})
            }
            else
            {
                Alert("Unable To Begin Game !! Please Try After Some Time")
                dispatch({type:GAME_BEGIN_FAIL})
            }
        }
        catch(e)
        {
            Alert(e.response?e.response.data.message:e.message)
            dispatch({type:GAME_BEGIN_FAIL})
        }
    }   
}

export const answerSubmit = (roomId,playerId,answer)=>{
    return async function(dispatch)
    {
        dispatch({type:ANSWER_SUBMIT_REQUEST})
        try
        {
            while(!(await axios.post(endpoint+"/api/game/firebasestatus",{roomId})).data.status);
            const data = await axios.post(endpoint+"/api/game/answer",{roomId,playerId,answer})
            if(data.data.status)
            {
                dispatch({type:ANSWER_SUBMIT_SUCCESS})
            }
            else
            {
                Alert("Unable To Save Your Thought !! Please Try After Some Time")
                dispatch({type:ANSWER_SUBMIT_FAIL})
            }
        }
        catch(e)
        {
            Alert(e.response?e.response.data.message:e.message)
            dispatch({type:ANSWER_SUBMIT_FAIL})
        }
    }   
}

export const answerPick = (roomId,playerId,pickId)=>{
    return async function(dispatch)
    {
        dispatch({type:ANSWER_PICK_REQUEST})
        try
        {
            while(!(await axios.post(endpoint+"/api/game/firebasestatus",{roomId})).data.status);
            const data = await axios.post(endpoint+"/api/game/pick",{roomId,playerId,pickId})
            if(data.data.status)
            {
                dispatch({type:ANSWER_PICK_SUCCESS})
            }
            else
            {
                Alert("Unable To Pick Answer !! Please Try After Some Time")
                dispatch({type:ANSWER_SUBMIT_FAIL})
            }
        }
        catch(e)
        {
            Alert(e.response?e.response.data.message:e.message)
            dispatch({type:ANSWER_SUBMIT_FAIL})
        }
    }   
}

export const ready = (roomId,playerId)=>{
    return async function(dispatch)
    {
        dispatch({type:PLAYER_READY_REQUEST})
        try
        {
            while(!(await axios.post(endpoint+"/api/game/firebasestatus",{roomId})).data.status);
            const data = await axios.post(endpoint+"/api/game/ready",{roomId,playerId})
            if(data.data.status)
            {
                dispatch({type:PLAYER_READY_SUCCESS})
            }
            else
            {
                Alert("Unable To Change Status !! Please Try After Some Time")
                dispatch({type:PLAYER_READY_FAIL})
            }
        }
        catch(e)
        {
            Alert(e.response?e.response.data.message:e.message)
            dispatch({type:PLAYER_READY_FAIL})
        }
    }   
}

export const nextStage = (roomId,admin)=>{
    return async function(dispatch)
    {
        dispatch({type:NEXT_STAGE_REQUEST})
        try
        {
            if(admin)
            {
                const data = await axios.post(endpoint+"/api/game/nextstage",{roomId})
                if(data.data.status)
                {
                    dispatch({type:NEXT_STAGE_SUCCESS})
                }
                else
                {
                    Alert("Unable To Change Stage !! Please Try After Some Time")
                    dispatch({type:NEXT_STAGE_FAIL})
                }
            }
            else
            {
                dispatch({type:NEXT_STAGE_SUCCESS})
            }
        }
        catch(e)
        {
            Alert(e.response?e.response.data.message:e.message)
            dispatch({type:NEXT_STAGE_FAIL})
        }
    }   
}
