import getSortedAnswers from "../../utils/Game/getSortedAnswers";
import "./AnswerList.css"

const AnswerList = ({answers,onClick})=>{
    return <div className="d-flex flex-column gap-2 answers col-10">
        {
            answers && Object.keys(getSortedAnswers(answers)).map((value,key)=>{
                return <button className="shadow shadow-lg shadow-light answerItem px-4 py-3 fs-4 col-12 text-center" onClick={onClick} id={value} key={key} >
                    {
                        answers[value].answer
                    }
                </button>
            })
        }
    </div>
}

export default AnswerList;