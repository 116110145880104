import Button from "../../../Components/Buttons/Button/Button"
import {useDispatch, useSelector} from "react-redux"
import { createRoom } from "../../../actions/room"
import "./Create.css"
import { useEffect, useState } from "react"
import {Navigate} from "react-router-dom"
import SimpleInput from "../../../Components/Input/SimpleInput/SimpleInput.jsx"
import Stages from "../../../Components/Stages/Stages"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Gender from "../../../Components/Gender/Gender.jsx"
import Header from "../../../Components/Header/Header.jsx"
import Mode from "../../../Components/Mode/Mode.jsx"
import encrypt from "../../../utils/Players/encrypt.js"

const Create = ()=>{
    const dispatch = useDispatch()
    const room = useSelector(state=>state.room)
    const [name,setName] = useState("")
    const [stage,setStage] = useState("");
    const [gender,setGender] = useState("");
    const [mode,setMode] = useState("");
    const [ipAddress, setIPAddress] = useState('')
    useEffect(()=>{
        fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setIPAddress(data.ip))
    })
    if(room.status)
    {
        return <Navigate to="/lobby" replace={true} />
    }

    
    
    const inputHandler = (e)=>{
        setName(e.target.value)
    }

    const stageHandler = (e)=>{
        setStage(e.target.checked?e.target.id:stage)
    }

    const genderHandler = (e)=>{
        setGender(e.target.checked?e.target.id:gender)
    }

    const modeHandler = (e)=>{
        setMode(e.target.checked?e.target.id:mode)
    }

    return <div className="welcome d-flex flex-column flex-wrap justify-content-center align-items-center py-1">
            <Header logoOnly={true} className="mt-5 mb-4" />
            <div className="col-md-6 col-sm-8 col-11 mt-2 d-flex justify-content-center bg-dark shadow-sm p-4 border border-4 rounded border-light flex-column gap-4 align-items-center">
                <SimpleInput label={"What's Your Name?"} value={name} onChange={inputHandler} />
                <h1 className="text-light text-center">Number OF Stages</h1>
                <Stages value={stage} onChange={stageHandler} />
                <Gender value={gender} onChange={genderHandler} />
                <Mode value={mode} onChange={modeHandler} />
                <Button title="Create Room" loading={room.loading} onClickEvent={()=>{dispatch(createRoom(name,stage,gender,mode,encrypt(ipAddress?ipAddress:"private")))}}/>
            </div>
            <ToastContainer />
        </div>
    
}

export default Create
