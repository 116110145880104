import {initializeApp} from "firebase/app"
import {getFirestore,collection} from "firebase/firestore"
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_NAME,
    messagingSenderId: process.env.REACT_APP_MSG_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
initializeApp(firebaseConfig)


const db = getFirestore()

const collectionRef = collection(db,"roomsV1");

export {db,collectionRef};
