import { useState } from "react";
import SimpleInput from "../../../../Components/Input/SimpleInput/SimpleInput";
import { ToastContainer } from 'react-toastify';
import LinkButton from "../../../../Components/Buttons/LinkButton/LinkButton";
import { useSearchParams } from "react-router-dom";
import Alert from "../../../../Components/Alert/Alert";
import Header from "../../../../Components/Header/Header";

const JoinRoom = ()=>{
    let [searchParams, setSearchParams] = useSearchParams();
    const [roomId,setRoomId] = useState("");
    if(searchParams.get("Error"))
    {
        setTimeout(()=>{Alert(searchParams.get("Error"));setSearchParams("Error","")},100)   
    }
    const inputHandler = (e)=>{
        setRoomId(e.target.value)
    }

    return <div className="welcome d-flex flex-column flex-wrap justify-content-center align-items-center py-1">
    <Header logoOnly={true} className="mt-5 mb-4" />
    <div className="col-md-6 col-sm-8 col-11 mt-2 d-flex justify-content-center bg-dark shadow-sm p-4 border border-4 rounded border-light flex-column gap-4 align-items-center">
        <SimpleInput label={"Enter Room ID"} value={roomId} onChange={inputHandler} />
        {/* <Stages value={stage} onChange={stageHandler} /> */}
        {/* <Gender value={gender} onChange={genderHandler} /> */}
        <LinkButton to={`/room/join/${roomId}`} title={"Join"} />
    </div>
    <ToastContainer />
</div>;
}

export default JoinRoom;