import "./Waiting.css"

const Waiting = ()=>{
    return <div className="col-12 d-flex justify-content-center align-items-center">
        <div id="load">
            <div>g</div>
            <div>n</div>
            <div>i</div>
            <div>t</div>
            <div>i</div>
            <div>a</div>
            <div>w</div>
    </div>
  </div>
}

export default Waiting;