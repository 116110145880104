import { useRef } from "react";
import "./ClipboardButton.css"
// import clipboardJs from "./ClipboardButton.js"
import copy from "copy-to-clipboard";

const ClipboardButton = ({roomId})=>{
    const clipboardFeedback = useRef(undefined);
    return <div className="clipboardButtonParent" onClick={()=>{copy(roomId?roomId:" ")}} onMouseEnter={()=>{clipboardFeedback.current.innerHTML="Copy to Clipboard";clipboardFeedback.current.classList.add("show")}} onMouseDown={()=>{clipboardFeedback.current.innerHTML="Copied!";clipboardFeedback.current.classList.add("show")}} onMouseLeave={()=>clipboardFeedback.current.classList.remove("show")} onMouseUp={()=>clipboardFeedback.current.classList.remove("show")}>
        <span className="tlt" ref={clipboardFeedback}>Copt to Clipboard</span>
        <div className="left">Room ID : {roomId}</div>
        <div className="right">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
      </div>
    </div>
    
}

export default ClipboardButton