import { ROOM_CREATE_FAIL, ROOM_CREATE_REQUEST, ROOM_CREATE_SUCCESS, ROOM_JOIN_FAIL, ROOM_JOIN_REQUEST, ROOM_JOIN_SUCCESS } from "../constants/constants"
import axios from "axios"
import { endpoint } from "../endpoint"
import Alert from "../Components/Alert/Alert"


export const createRoom = (name,stages,gender,mode,secret)=>{
    return async (dispatch)=>{
        dispatch({type:ROOM_CREATE_REQUEST})
        try
        {
            if(!name)
            {
                Alert("Name is Required !!")
                dispatch({type:ROOM_CREATE_FAIL})
            }
            else if(!stages)
            {
                Alert("Stage is Required !!")
                dispatch({type:ROOM_CREATE_FAIL})
            }
            else if(!mode)
            {
                Alert("Please Select a Mode !!")
                dispatch({type:ROOM_CREATE_FAIL})
            }
            else
            {
                const data = await axios.post(endpoint+"/api/room/create",{name,stages,gender,mode,secret})
                if(data.data.status)
                {
                    Alert("Room Created Successfully",true)
                    dispatch({type:ROOM_CREATE_SUCCESS,payload:{playerId:data.data.message.playerId,roomId:data.data.message.roomId,avatar:data.data.message.avatar,name:data.data.message.name}})
                }
                else
                {
                    Alert(data.data.response.message)
                    dispatch({type:ROOM_CREATE_FAIL})
                }
            }
        }
        catch(e)
        {
            console.log(e);
            Alert(e.response?e.response.data.message:e.message)
            dispatch({type:ROOM_CREATE_FAIL})   
        }
    }
}

export const joinRoom = (roomId,name,gender,secret)=>{
    return async (dispatch)=>{
        dispatch({type:ROOM_JOIN_REQUEST})
        try
        {
            if(!roomId)
            {
                window.location.href="/room/join/Error=Room ID is Required"
                dispatch({type:ROOM_JOIN_FAIL})
            }
            else
            {
                const data = await axios.post(endpoint+"/api/room/join",{roomId,name,gender,secret})
                if(data.data.status)
                {
                    dispatch({type:ROOM_JOIN_SUCCESS,payload:{playerId:data.data.message.playerId,roomId:data.data.message.roomId,avatar:data.data.message.avatar,name:data.data.message.name}})
                }
                else
                {
                    Alert("Unable To Join Room !! Please Try After Some Time")
                    dispatch({type:ROOM_JOIN_FAIL})
                }
            }
        }
        catch(e)
        {
            console.log(e);
            Alert(e.response?e.response.data.message:e.message)
            dispatch({type:ROOM_CREATE_FAIL})   
        }
    }
}