import { useState } from "react";
import { ready } from "../../actions/game";
import getNewScore from "../../utils/Game/getNewScore";
import getUpdatedPlayers from "../../utils/Game/getUpdatedPlayers";
import getName from "../../utils/Players/getName"
import Button from "../Buttons/Button/Button"
import "./LeaderBoard.css"
import { useDispatch, useSelector } from 'react-redux';

const LeaderBoard = ({players,currentTrivia,showReady=true,results=false,totalStages=0,currentStage=0})=>{
    const dispatch = useDispatch();
    const room = useSelector(state=>state.room)
    const readyHandler = async ()=>{
        dispatch(ready(room.roomId,room.playerId))
    }
    let playerStatus = currentTrivia.playerStatus;
    const newScore = getNewScore(playerStatus);
    let [updatedPlayers,setUpdatedPlayers] = useState({...players})
    results?
    setTimeout(()=>{
        setUpdatedPlayers({...getUpdatedPlayers(players,newScore)})
    },0):
    setTimeout(()=>{
        setUpdatedPlayers({...getUpdatedPlayers(players,newScore)})
    },1000);
    return <>
        	{
                <div className="d-flex flex-column justify-content-center">
                    <h1 className="text-center">Leaderboard
                        <img src="/Assets/Images/leaderboard.png" className="img-fluid col-2 col-md-1 px-1   " alt="Leaderboard" />
                    </h1>
                    {
                        !results && <h6 className="text-center text-secondary">Level ({currentStage}/{totalStages})</h6>
                    }
                </div>
            }
            <ul className="leaderboard d-flex flex-column justify-content-center align-items-center gap-4 col-10 p-0">
                {
                    updatedPlayers && Object.keys(updatedPlayers).map((value,key)=>playerStatus[value]?
                        <div className="player d-flex justify-content-center align-items-center col-12" style={{"--number":key}} key={key} >
                            <div className="col-3 col-md-2 text-center p-1">
                                <img src={updatedPlayers[value].avatar} className="avatar col-10 rounded-circle" alt={getName(value,updatedPlayers[value].playerId,updatedPlayers[value].name)} />
                            </div>
                            <p className={`${results?"name":"nameAnimate"} col-md-7 p-2 col-6 fs-5 m-0 d-flex justify-content-between`}>    
                                <p className="d-flex flex-column justify-content-center align-items-start m-0">
                                    <span className="fw-bold text-secondary userName">
                                        {getName(value,updatedPlayers[value].playerId,updatedPlayers[value].name)}
                                    </span>  
                                    {
                                        !results && <span className="text-dark me-auto answer">
                                            {playerStatus[value].answer}
                                        </span>
                                    }  
                                </p>
                                {
                                    <span className={`text-success ${results?"newScore":"newScoreAnimate"} d-flex justify-content-center align-items-center fs-2`}>+{newScore[value]}</span>
                                }
                            </p>
                            {
                                <p className="score col-md-3 p-2 col-3 bg-secondary text-light fs-2 m-0 d-flex justify-content-center align-items-center flex-row"> {updatedPlayers[value].score}</p>
                            }
                        </div>:
                        <></>
                    )
                }
            </ul>
            {
                showReady && 
                <Button title={"Ready"} onClickEvent={readyHandler} loading={room.loading} />
            }
    </>
}

export default LeaderBoard