import "./Gender.css"

const Gender = ({value,onChange})=>{
	const choiceSound = new Audio("/Assets/Sounds/choice.mp3");
    return <div className="InputGroupGender px-5 gender d-flex flex-column justify-content-center align-items-center"> 
		<div className="d-flex">
			<input type="radio" name="gender" id="male" value={value} onChange={(e)=>{choiceSound.play().then(()=>{}).catch(()=>{});onChange(e)}}/>
			<label htmlFor="male" className="d-flex flex-column justify-content-between" ><img src="/Assets/Images/male.png" className="img-fluid py-3 col-12" alt="Male" /><h5>Male</h5></label>
			<input type="radio" name="gender" id="female" value={value} onChange={(e)=>{choiceSound.play().then(()=>{}).catch(()=>{});onChange(e)}}/>
			<label htmlFor="female" className="d-flex flex-column justify-content-between" ><img src="/Assets/Images/female.webp" className="img-fluid py-3 col-12" alt="Female" /><h5>Female</h5></label>
		</div>
		<div className="col-12 pt-3">
			<h5 className="text-light text-center">(Optional)</h5>
		</div>
	</div>
}

export default Gender