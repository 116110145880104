import { useEffect, useState } from "react";
import Avatar from "../../Components/Avatar/Avatar"
import Button from "../../Components/Buttons/Button/Button"
import { useDispatch, useSelector } from 'react-redux';
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../Firebase/config";
import ClipboardButton from "../../Components/Buttons/ClipboardButton/ClipboardButton";
import getName from "../../utils/Players/getName";
import { begin } from "../../actions/game";
import { ToastContainer } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import Waiting from "../../Components/Loader/Waiting/Waiting";
import Header from "../../Components/Header/Header";

const Lobby = ()=>{

    const room = useSelector(state=>state.room)
    const dispatch = useDispatch();
    const docRef = doc(db,'roomsV1',room.roomId)
    const [players,setPlayers] = useState(undefined)
    const [gameStatus,setGameStatus] = useState(false) 
    useEffect(()=>{
        room && room.status &&
        onSnapshot(docRef,(docs)=>{
            setPlayers(docs.data())
            setGameStatus(docs.data().gameStatus)
        })
        const roomJoinedSound = new Audio("/Assets/Sounds/roomJoined.mp3");
        roomJoinedSound.play().then(()=>{}).catch(()=>{});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    if(gameStatus)
    {
        return <Navigate to="/game" />
    }


    return <div className="welcome d-flex flex-column justify-content-center align-items-center py-1">
    <Header logoOnly={true} className="mt-5 mb-4" />
    {
        room && 
        <div>
            <ClipboardButton roomId={room.roomId} />
        </div>
    }
    <div className="col-md-8 col-10 mt-5 d-flex justify-content-center flex-column border border-4 rounded border-light bg-dark p-4 gap-4 align-items-center ">
        <h2 className="text-center text-light">Players Joined</h2>
        <div className="d-flex gap-4 col-8 justify-content-center flex-wrap">
            {
                players && Object.keys(players.players).map((value,key)=><Avatar id={value} key={key} name={getName(value,room.playerId,players.players[value].name)} src={players.players[value].avatar} />)
            }
        </div>
        {
            players && players.players[room.playerId].admin ?
            <Button title="Let's Play" onClickEvent={()=>{dispatch(begin(room.roomId))}} loading={room.loading} />:
            <Waiting />
        }
    </div>
    <ToastContainer />
</div>
}

export default Lobby