export const ROOM_CREATE_REQUEST = "ROOM_CREATE_REQUEST";
export const ROOM_CREATE_SUCCESS = "ROOM_CREATE_SUCCESS";
export const ROOM_CREATE_FAIL = "ROOM_CREATE_FAIL";

export const ROOM_JOIN_REQUEST = "ROOM_JOIN_REQUEST";
export const ROOM_JOIN_SUCCESS = "ROOM_JOIN_SUCCESS";
export const ROOM_JOIN_FAIL = "ROOM_JOIN_FAIL";

export const GAME_BEGIN_REQUEST = "GAME_BEGIN_REQUEST";
export const GAME_BEGIN_SUCCESS = "GAME_BEGIN_SUCCESS";
export const GAME_BEGIN_FAIL = "GAME_BEGIN_FAIL";

export const ANSWER_SUBMIT_REQUEST = "ANSWER_SUBMIT_REQUEST";
export const ANSWER_SUBMIT_SUCCESS = "ANSWER_SUBMIT_SUCCESS";
export const ANSWER_SUBMIT_FAIL = "ANSWER_SUBMIT_FAIL";

export const ANSWER_PICK_REQUEST = "ANSWER_PICK_REQUEST";
export const ANSWER_PICK_SUCCESS = "ANSWER_PICK_SUCCESS";
export const ANSWER_PICK_FAIL = "ANSWER_PICK_FAIL";

export const PLAYER_READY_REQUEST = "PLAYER_READY_REQUEST";
export const PLAYER_READY_SUCCESS = "PLAYER_READY_SUCCESS";
export const PLAYER_READY_FAIL = "PLAYER_READY_FAIL";

export const NEXT_STAGE_REQUEST = "NEXT_STAGE_REQUEST";
export const NEXT_STAGE_SUCCESS = "NEXT_STAGE_SUCCESS";
export const NEXT_STAGE_FAIL = "NEXT_STAGE_FAIL";
