import LinkButton from "../../Components/Buttons/LinkButton/LinkButton";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "./Home.css"

const Home = ()=>{
    return <>
        <div className="welcome d-flex flex-column justify-content-center align-items-center py-1">
            <Header />
            <div className="col-4 mt-5 d-flex justify-content-center flex-column gap-4 align-items-center">
                <LinkButton title="Create Room" to={"/room/create"} />
                <LinkButton title="Join Room" to={"/room/join"} />
            </div>
        </div>
        <Footer />
    </>
}

export default Home